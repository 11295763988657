import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@/app';
import './index.css';
import { ENVS } from '@/config/env.config';
import { getDefaultConfig, darkTheme, RainbowKitProvider, connectorsForWallets, Chain } from '@rainbow-me/rainbowkit';
import { WagmiProvider, createConfig, http } from 'wagmi';
import { mainnet, bscTestnet, bsc } from 'wagmi/chains';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "@rainbow-me/rainbowkit/styles.css"
import { rainbowWallet, metaMaskWallet, coinbaseWallet, walletConnectWallet, bitgetWallet, binanceWallet, coin98Wallet, trustWallet, safeWallet } from '@rainbow-me/rainbowkit/wallets';

const rootElement = document.querySelector('[data-js="root"]');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(rootElement);

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [rainbowWallet, metaMaskWallet, bitgetWallet, binanceWallet, coin98Wallet, trustWallet, safeWallet],
    },
    {
      groupName: 'Others',
      wallets: [coinbaseWallet, walletConnectWallet],
    },
  ],
  { appName: 'Misscharm Meme App', projectId: '98aa274a849e321c7c9c4a2f605ec809' },
);

const chains: readonly [Chain, ...Chain[]] = [
  mainnet, bscTestnet, bsc
];


// const config = getDefaultConfig({
//   appName: 'My RainbowKit App',
//   projectId: '98aa274a849e321c7c9c4a2f605ec809',
//   chains: [mainnet, bsc, bscTestnet],
//   ssr: true,
// });
const config = createConfig({
  chains: [bsc],
  transports: {
    [bsc.id]: http(),
    // [bscTestnet.id]: http()
  },
  connectors
});
const queryClient = new QueryClient();
// const config = getDefaultConfig({
//   appName: 'My RainbowKit App',
//   projectId: '98aa274a849e321c7c9c4a2f605ec809',
//   chains: [mainnet, polygon, optimism, arbitrum, base],
//   ssr: true,
// });


root.render(
  <StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme({ accentColor: '#fb923c', fontStack: 'system' })} initialChain={97}>
          <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>

  </StrictMode>
);

