import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { Loading } from '@/components/loading/loading';
import appBg from '@/assets/bg.png';
import frame from '@/assets/icon/frame.png'
import { Footer } from '@/components/footer/footer';
import { useNavigate } from 'react-router';
import { getAiSquad, getAllAiSquad } from '@/utilities/miss-data';
import LeftArrow from '@/assets/left-arrow.svg';
import { Wrapper } from './components/details.styled';
type AuthData = {
    user_id: number;
    username?: string;
    address?: string;
    country?: string;
    miss: string;
    avatar: string;
    flag: string;
    ai_avatar: string;
    squad_id: string;
};
const ListMemeComponent = () => {
    const [squadSet, setSquadSet] = useState<any>([]);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        async function fetchAllSquad() {
            const response = await getAllAiSquad();
            console.log('data', response)
            if (response) {
                setSquadSet(response.data);
            }
        }
        fetchAllSquad();
    }, []);
    const [user, setUser] = useState<AuthData | null>(null);
    useEffect(() => {
        try {
            const storedData = localStorage.getItem('authData');
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                if (parsedData && parsedData.id && parsedData.username) {
                    getAiSquad(parsedData.id).then((squadResponse) => {
                        if (squadResponse && squadResponse.data && squadResponse.data.length > 0) {
                            setUser(squadResponse.data[0]);
                        }
                    }).catch((error) => {
                        console.error('Error fetching squad data:', error);
                    });
                } else {
                    console.error('Clearing data.');
                    localStorage.removeItem('authData');
                }
            }
        } catch (error) {
            localStorage.removeItem('authData');
        }
    }, []);
    function formatNumber(num: number): string {
        let formattedNum = num.toString();

        if (formattedNum.includes('.')) {
            formattedNum = formattedNum.replace(/(\.\d*?[1-9])0+$/g, '$1');
        }

        formattedNum = formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return formattedNum;
    }
    const [isLoading, setIsLoading] = useState(true);

    // useLayoutEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 3000);
    // }, []);
    return (
        <Wrapper>
            {/* {isLoading && <Loading />} */}
            <div className='desktop-display'>

                <div className='w-100 flex justify-center'>
                    <div
                        className='w-screen h-full mx-12' style={{ maxWidth: "1280px", }}
                    >
                        <div className='block p-12 rounded-2xl'

                        >
                            <div className='flex pt-8 pb-8'>
                                <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/memes')} />
                                <div className='ml-8 secondary-font text-[80px] font-normal'>
                                    Miss Charm Meme Coin
                                </div>
                            </div>
                            <div className='flex flex-wrap' data-aos="fade-up">
                                {squadSet &&
                                    squadSet.map &&
                                    squadSet.map(
                                        (
                                            miss: {
                                                id: string | undefined;
                                                avatar: string | undefined;
                                                ai_avatar: string | undefined;
                                                QRCode: string | undefined;
                                                miss: string | undefined;
                                                country: string | undefined;
                                                symbol_meme: string;
                                                market_cap: any;
                                                gta: any
                                            },
                                            index: number | 0
                                        ) => (
                                            <div key={index} className='w-1/4 px-4'>
                                                <div className='w-full mb-6'>
                                                    <div className="relative" onClick={() => navigate(`/memes/detail/${miss.id}`)}>
                                                        <img src={frame} alt="absolute" className="relative z-10" />
                                                        <img
                                                            src={miss.ai_avatar}
                                                            alt=""
                                                            className="absolute inset-0 w-full h-full object-cover"
                                                        />
                                                        {/* {user && user?.squad_id == miss.id &&
                                                            <div className='flex border-[#00FF00] border absolute bottom-6 right-3 rounded-2xl justify-center items-center p-2' style={{ zIndex: '10', width: '40%' }}>
                                                                <span className='fw-bold text-sm text-[#00FF00]'>Your Squad</span>
                                                            </div>
                                                        } */}
                                                    </div>
                                                    <div className='bg-[#222933]' style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: "10px" }}>
                                                        <div className='px-3 py-3 pb-0 flex justify-between'>
                                                            <div>
                                                                <span className='block text-[#00FF00]'>${miss.symbol_meme}</span>
                                                                <span>Miss {miss.country}</span>
                                                            </div>
                                                            {/* <span>$SEL</span> */}
                                                        </div>
                                                        <div className='px-3 py-3 pb-4 flex justify-between'>
                                                            <div className='flex items-end space-x-1'>
                                                                <span className='text-base'>
                                                                    ${`${formatNumber(parseInt(miss.market_cap))}`}
                                                                </span>
                                                                <div>
                                                                    {
                                                                        miss.gta > 0 ? (
                                                                            <span className='text-xs text-green-500'>
                                                                                ({`${formatNumber(miss.gta)}`}%)
                                                                            </span>
                                                                        ) : miss.gta < 0 ? (
                                                                            <span className='text-xs text-red-500'>
                                                                                ({`${formatNumber(miss.gta)}`}%)
                                                                            </span>
                                                                        ) : (
                                                                            <span className='text-xs text-green-500'>
                                                                                (0%)
                                                                            </span>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <span>Market Cap</span>
                                                        </div>
                                                        <div className='pb-8 border-t border-[#605656]'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobile-display'>

                <div className='flex mx-4 py-10'>
                    <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/memes')} />
                    <div className='ml-8 primary-font text-xl font-semibold'>
                        Miss Charm Meme Coin
                    </div>
                </div>
                <div className='flex flex-wrap mx-2'>
                    {squadSet &&
                        squadSet.map &&
                        squadSet.map(
                            (
                                miss: {
                                    id: string | undefined;
                                    ai_avatar: string | undefined;
                                    QRCode: string | undefined;
                                    miss: string | undefined;
                                    country: string | undefined;
                                    symbol_meme: string;
                                    market_cap: any;
                                    gta: any
                                },
                                index: number | 0
                            ) => (
                                <div key={index} className='w-1/2 px-2'>
                                    <div className=' w-full mb-6'>
                                        <div className="relative" onClick={() => navigate(`/memes/detail/${miss.id}`)}>
                                            <img src={frame} alt="absolute" className="relative z-10" />
                                            <img
                                                src={miss.ai_avatar}
                                                alt=""
                                                className="absolute inset-0 w-full h-full object-cover"
                                            />
                                            {/* {user && user?.squad_id == miss.id &&
                                                <div className='flex border-[#00FF00] border absolute bottom-6 right-3 rounded-2xl justify-center items-center p-2' style={{ zIndex: '10', width: '50%' }}>
                                                    <span className='fw-bold text-sm text-[#00FF00]'>Your Squad</span>
                                                </div>
                                            } */}
                                        </div>
                                        <div className='bg-[#222933]' style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: "10px" }}>
                                            <div className='px-2 pt-2'>
                                                <span className='block text-[#00FF00]'>${miss.symbol_meme}</span>
                                                <span className='text-sm'>Miss {miss.country}</span>
                                            </div>
                                            {/* <div className='px-2 flex justify-between'>
                                                <span className='text-[#00FF00]'>${formatNumber(parseInt(miss.market_cap))}</span>
                                            </div> */}
                                            <div className='px-2 flex items-end space-x-1'>
                                                <span className='text-base'>
                                                    ${`${formatNumber(parseInt(miss.market_cap))}`}
                                                </span>
                                                <div>
                                                    {
                                                        miss.gta > 0 ? (
                                                            <span className='text-xs text-green-500'>
                                                                ({`${formatNumber(miss.gta)}`}%)
                                                            </span>
                                                        ) : miss.gta < 0 ? (
                                                            <span className='text-xs text-red-500'>
                                                                ({`${formatNumber(miss.gta)}`}%)
                                                            </span>
                                                        ) : (
                                                            <span className='text-xs text-green-500'>
                                                                (0%)
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className='px-2 pb-3 text-sm'>Market Cap</div>
                                            <div className='pb-8 border-t border-[#605656]'></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                </div>
            </div>
            <div className='mt-24'>
                <Footer />
            </div>
        </Wrapper>
    );
};

export const ListMeme = memo(ListMemeComponent);
